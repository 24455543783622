// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-estimate-tsx": () => import("./../../../src/pages/estimate.tsx" /* webpackChunkName: "component---src-pages-estimate-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-services-concrete-tsx": () => import("./../../../src/pages/services/concrete.tsx" /* webpackChunkName: "component---src-pages-services-concrete-tsx" */),
  "component---src-pages-services-framing-tsx": () => import("./../../../src/pages/services/framing.tsx" /* webpackChunkName: "component---src-pages-services-framing-tsx" */),
  "component---src-pages-services-handyman-services-tsx": () => import("./../../../src/pages/services/handyman-services.tsx" /* webpackChunkName: "component---src-pages-services-handyman-services-tsx" */),
  "component---src-pages-services-masonry-tsx": () => import("./../../../src/pages/services/masonry.tsx" /* webpackChunkName: "component---src-pages-services-masonry-tsx" */),
  "component---src-pages-services-roofing-tsx": () => import("./../../../src/pages/services/roofing.tsx" /* webpackChunkName: "component---src-pages-services-roofing-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */)
}

